import React from 'react';
import Checkbox from '@mui/material/Checkbox'; 

const Task = ({ task, chats, executorAvatars, handleTaskClick, handleTaskComplete, formatDeadline, mainPage = true, completed = false }) => {
  
  const getFirstCharacter = (name) => {
    if (!name) return '?'; 
    return [...name][0];
  };

  return (
    <div
      key={task.id}
      className="task-container"
      onClick={() => handleTaskClick(task)}
    >
      {!completed && task.important && (
        <img
          src="/icons/ImportantIcon.svg"
          alt="Important"
          className="important-icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      )}
      <div className="checkbox-task">
        <Checkbox
          checked={task.completed}
          onClick={(e) => e.stopPropagation()} // Предотвращаем всплытие
          onChange={() => handleTaskComplete(task.id)}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{
            padding: 0,
            color: 'white', // Цвет чекбокса, когда он не отмечен
            '&.Mui-checked': {
              color: 'white', // Цвет чекбокса, когда он отмечен
            },
          }}
        />
      </div>
      <div className="task-content">
        <div className="task-title">
          {task.name}

          <div className="executor-avatars">
            {!completed && !mainPage && task.executor_ids && task.executor_ids.map((executorId, index) => {
              const avatarUrl = executorAvatars[executorId];
              return avatarUrl ? (
                <img
                  key={executorId}
                  className="executor-avatar"
                  src={avatarUrl}
                  alt="Executor Avatar"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    marginLeft: "8px",
                  }}
                />
              ) : (
                <div
                  key={executorId}
                  className="executor-placeholder"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "8px",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  {task.executors && task.executors[index] ? getFirstCharacter(task.executors[index]) : "?"}
                </div>
              );
            })}
          </div>
        </div>
        <div className="task-meta">
          {mainPage && (() => {
            if (!task.chat_id) {
              return null;
            }

            const chat = chats.find(chat => chat.id === task.chat_id);

            if (chat) {
              return <span className="task-project">{chat.name}</span>;
            } else {
              return <span className="task-project">Unknown chat</span>;
            }
          })()}
          
          {task.deadline && (
            <div className="task-deadline">
              <img
                src="/img/RedFlag.svg"
                alt="RedFlag"
                className="red-flag"
              />
              <span className="task-date">
                {formatDeadline(new Date(task.deadline))} {/* Используем форматирование для правильного отображения времени */}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Task;
