import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import WebApp from "@twa-dev/sdk";
import Checkbox from '@mui/material/Checkbox'; 
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import Task from './molecules/Task';
import enGB from 'date-fns/locale/en-GB'; 

registerLocale('en-GB', enGB);

const useAutoResizeTextarea = (textareaRef, value) => {
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [value, textareaRef]); // Update height when value or ref changes
};

const App = () => {
  const [tasks, setTasks] = useState([]);
  const [user, setUser] = useState({});
  const [isTaskInputVisible, setIsTaskInputVisible] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [selectedExecutor, setSelectedExecutor] = useState([]);
  const [selectedExecutorId, setSelectedExecutorId] = useState([]);
  const [deadline, setDeadline] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");
  const [showAllTasks, setShowAllTasks] = useState(false);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false); 
  const [chats, setChats] = useState([]);
  const [isImportant, setIsImportant] = useState(false);
  const taskInputRef = useRef(null);
  const taskFormRef = useRef(null);
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [chatMembersCount, setChatMembersCount] = useState({});
  const [chatAvatars, setChatAvatars] = useState({}); 
  const tg = WebApp;
  const datePickerRef = useRef(null);
  tg.ready(); 
  const [socket, setSocket] = useState(null); 

  // Кэширование данных в localStorage
  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const toMoscowTime = (date) => {
    const moscowOffset = 3 * 60 * 60 * 1000; // UTC+3
    return new Date(date.getTime() + moscowOffset);
  };
  const getFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };

  // Function to check user membership in a chat
  const checkMembership = async (taskId) => {
    try {
      const user_id2 = tg.initDataUnsafe?.user?.id;
      const response = await fetch(`${apiUrl}/check-membership?taskId=${taskId}&userId=${user_id2}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data.isMember;
      } else {
        console.error("Failed to check membership");
        return false;
      }
    } catch (error) {
      console.error("Error checking membership:", error);
      return false;
    }
  };

  useEffect(() => {
    const startParam = tg.initDataUnsafe?.start_param;
    const userId2 = tg.initDataUnsafe?.user?.id;
    const fetchUserChats2 = async () => {
      try {
        const response = await fetch(`${apiUrl}/get-user-chats/?userId=${userId2}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const chatsArray = data.chats || [];
          setChats(chatsArray);
          saveToLocalStorage('chats', chatsArray);

          chatsArray.forEach(chat => {
            fetchChatAvatar(chat.id);
            fetchMembers(chat.id);
          });

          return chatsArray;
        } else {
          console.error("Failed to fetch user chats");
          return [];
        }
      } catch (error) {
        console.error("Error fetching user chats:", error);
        return [];
      }
    };
    const handleNavigation = async () => {
      if (startParam) {
        if (startParam.startsWith('chat_')) {
          const chatsArray = await fetchUserChats2();
          const [_, chatId] = startParam.split('_');
          const chat = chatsArray.find(chat => String(chat.id) === String(chatId));

          if (chat) {
            navigate(`/chat/${chatId}`, {
              state: {
                chatId: chatId,
                chatName: chat.name || 'No Project',
              },
            });
          } else {
            console.error('Chat not found');
          }
        } else if (startParam.startsWith('task_')) {
          const taskId = startParam.replace('task_', '');

          const isMember = await checkMembership(taskId);

          if (isMember) {
            navigate(`/EditTask/${taskId}`, { state: { from: 'main' } });

          } else {
            console.error('User is not authorized to view this task:', taskId);
          }
        }

        const updatedUrl = new URL(window.location);
        updatedUrl.searchParams.delete('startapp');
        window.history.replaceState({}, document.title, updatedUrl);
        tg.initDataUnsafe.start_param = null;
      }
    };

    handleNavigation();
  }, [navigate, chats, user.user_id]); 

  const apiUrl =
    process.env.NODE_ENV === "production"
      ? "https://test.tasks.ledokol.it/api2"
      : "http://localhost:5001";

  const handleChatClick = (chat) => {
    if (chat && chat.id) {
      navigate(`/chat/${chat.id}`, {
        state: {
          chatId: chat.id,
          chatName: chat.name,
        },
      });
    } else {
      console.error("Chat data is incomplete or missing.");
    }
  };

  const fetchUserChats = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-user-chats/?userId=${user.user_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const chatsArray = data.chats || [];
        setChats(chatsArray);
        saveToLocalStorage('chats', chatsArray);
  
        chatsArray.forEach(chat => {
          fetchChatAvatar(chat.id);
          fetchMembers(chat.id);
        });
      } else {
        console.error("Failed to fetch user chats");
      }
    } catch (error) {
      console.error("Error fetching user chats:", error);
    }
  };

  // Функция для загрузки аватарок чатов из кеша или с сервера
  const fetchChatAvatar = async (chatId) => {
    const cachedAvatar = getFromLocalStorage(`chatAvatar_${chatId}`);
    if (cachedAvatar) {
      setChatAvatars((prevAvatars) => ({
        ...prevAvatars,
        [chatId]: cachedAvatar,
      }));
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/get-chat-avatar?chatId=${chatId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        const avatar = data.chatAvatar || "";
        setChatAvatars((prevAvatars) => ({
          ...prevAvatars,
          [chatId]: avatar,
        }));
        saveToLocalStorage(`chatAvatar_${chatId}`, avatar); // Сохраняем в кеш
      } else {
        console.error('Failed to fetch chat avatar');
      }
    } catch (error) {
      console.error('Error fetching chat avatar:', error);
    }
  };

  // Функция для загрузки участников чатов из кеша или с сервера
  const fetchMembers = async (chatId) => {
    const cachedMembers = getFromLocalStorage(`chatMembers_${chatId}`);
    if (cachedMembers) {
      setChatMembersCount((prevCounts) => ({
        ...prevCounts,
        [chatId]: cachedMembers.length,
      }));
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/get-chat-members?chatId=${chatId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        const members = data.members || [];
        setChatMembersCount((prevCounts) => ({
          ...prevCounts,
          [chatId]: members.length,
        }));
        saveToLocalStorage(`chatMembers_${chatId}`, members); // Сохраняем в кеш
      } else {
        console.error('Failed to fetch chat members');
      }
    } catch (error) {
      console.error('Error fetching chat members:', error);
    }
  };

  useEffect(() => {
    if (selectedProject) {
      const fetchMembers = async () => {
        try {
          const response = await fetch(`${apiUrl}/get-chat-members?chatId=${selectedProject}`);
          const data = await response.json();
          setMembers(data.members || []); 
        } catch (error) {
          console.error("Error fetching chat members:", error);
        }
      };

      fetchMembers();
    }
  }, [selectedProject]);

  useEffect(() => {
    const cachedChats = getFromLocalStorage('chats');
    if (cachedChats) {
      setChats(cachedChats);
    }

    if (user.user_id) {
      fetchUserChats(); 
      const intervalId = setInterval(fetchUserChats, 5000);

      return () => clearInterval(intervalId); 
    }
  }, [user.user_id]);

  const handleTaskClick = (task) => {
    if (task && task.id) {
      navigate(`/EditTask/${task.id}`);
    } else {
      console.error("Task data is incomplete or missing.");
    }
  };

  const handleTaskComplete = async (taskId) => {
    if (!taskId) {
      console.error("Task ID is required.");
      return;
    }

    const task = tasks.find((task) => task.id === taskId);
    if (!task) {
      console.error("Task not found.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/complete-task/${taskId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: !task.completed,
          user_id: user.user_id,
        }),
      });

      if (response.ok) {
        fetchTasks();
      } else {
        console.error("Failed to update task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const handleTaskComplete2 = async (taskId) => {
    if (!taskId) {
      console.error("Task ID is required.");
      return;
    }

    const task = tasks.find((task) => task.id === taskId);
    if (!task) {
      console.error("Task not found.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/complete-task2/${taskId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: false,
        }),
      });

      if (response.ok) {
        await fetchTasks(); 
      } else {
        console.error("Failed to update task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };
  
  const fetchTasks = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-tasks/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const filteredTasks = data.tasks.filter((task) => {
          const cleanExecutor = task.executor ? task.executor.replace('@', '') : '';
          return (
            cleanExecutor === user.username ||  
            (task.chat_id === '0' || task.chat_id === '') && task.author === user.username
          );
        });
  
        setTasks(filteredTasks || []);
        saveToLocalStorage('tasks', filteredTasks || []);
      } else {
        console.error("Failed to fetch tasks");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  
  useAutoResizeTextarea(taskInputRef, taskName);

  useEffect(() => {
    const cachedTasks = getFromLocalStorage('tasks');
    if (cachedTasks) {
      setTasks(cachedTasks);
    }

    const fetchProfilePhoto = async (userId) => {
      if (!userId) return "/img/default-profile.png";
      try {
        const response = await fetch(
          `${apiUrl}/get-profile-photo?userId=${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        return data.photoUrl || "/img/default-profile.png";
      } catch (error) {
        console.error("Error fetching profile photo:", error);
        return "/img/default-profile.png";
      }
    };

    const initUser = async () => {
      const userId = tg.initDataUnsafe?.user?.id;
      if (userId) {
        const photoUrl = await fetchProfilePhoto(userId);
        setUser({
          username: tg.initDataUnsafe?.user?.username || "Username",
          profilePhoto: photoUrl,
          firstName: tg.initDataUnsafe?.user?.first_name || "Pro",
          user_id: userId,
        });
      } else {
        console.error("User ID is not available.");
      }
    };

    initUser();
    fetchTasks();

    const handleClickOutside = (event) => {
      if (
        taskFormRef.current &&
        !taskFormRef.current.contains(event.target) &&
        !event.target.closest(".no-close")
      ) {
        handleTaskFormClose();
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [apiUrl, user.user_id]);

  const getFirstCharacter = (name) => {
    if (!name) return '?'; 
    return [...name][0];
  };

  const handleTaskInputToggle = (event) => {
    event.stopPropagation();
    setIsTaskInputVisible(true);
  
    if (!isTaskInputVisible) {
      setTimeout(() => {
        if (taskInputRef.current) {
          taskInputRef.current.focus();
        }
      }, 0);
    }
  };
  
  useEffect(() => {
    if (isTaskInputVisible && taskInputRef.current) {
      taskInputRef.current.focus();
    }
  }, [isTaskInputVisible]);

  const handleTaskFormClose = () => {
    setIsTaskInputVisible(false);
    setTaskName("");
    setDeadline(null);
    setSelectedProject("");
    setSelectedExecutor([]);
    setSelectedExecutorId([]);
    setIsImportant(false);
  };

  const formatDeadline = (date) => {
    if (!date) return "No deadline";
  

    const options = { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: false,
      timeZone: 'UTC'
    };
    
    return new Date(date).toLocaleString('en-GB', options);
  };
  

  const handleDeadlineClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleCreateTask = async () => {
    const moscowDeadline = deadline ? toMoscowTime(new Date(deadline)) : null;
    const formattedDeadline = moscowDeadline 
      ? moscowDeadline.toISOString().split('.')[0]  // Оставляем только часть без миллисекунд
      : null;
      
    try {
      const response = await fetch(`${apiUrl}/create-task/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: taskName,
          deadline: formattedDeadline,
          chatId: selectedProject,
          executor: selectedExecutor,
          executor_id: selectedExecutorId,
          author: user.username,
          user_id: user.user_id,
        }),
      });
  
      if (response.ok) {
        const newTask = await response.json();
        setTasks([...tasks, newTask.task]);
        saveToLocalStorage('tasks', [...tasks, newTask.task]);
        handleTaskFormClose();
      } else {
        console.error("Failed to create task");
      }
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };
  

  const handleShowAllTasks = () => {
    if (isTaskInputVisible) {
      setIsTaskInputVisible(true); 
    }
    setShowAllTasks(true);
  };

  const handleToggleCompletedTasks = () => {
    if (isTaskInputVisible) {
      setIsTaskInputVisible(true); 
    }

    setShowCompletedTasks((prevState) => !prevState);  
  };

  const handleAddToChat = () => {
    const tg = WebApp;
    tg.openTelegramLink("https://t.me/task_trackerfj_bot?startgroup=true");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleCreateTask();
    }
  };
  const completedTasks = tasks.filter((task) => task && task.completed);
  const filteredTasks = tasks.filter(
    (task) => task && !task.completed  
  );
  const displayedTasks = [...filteredTasks].reverse();
  const visibleTasks = showAllTasks
  ? displayedTasks
  : displayedTasks.slice(0, 4);

  return (
    <div className="root">
      <div className="view">
        <div className="frame">
          <div className="div">
            <img className="image" alt="Profile" src={user.profilePhoto} />
            <div className="text-wrapper">{user.username}</div>
          </div>
          <div className="add-to-chat" onClick={handleAddToChat}>
            <img className="img" alt="Star Icon" src="/img/addicon.svg" />
            <div className="frame-2">
              <div className="text-wrapper-2">Add to chat</div>
            </div>
          </div>
        </div>
      </div>

      <div className="screen" ref={taskFormRef}>
        <div className="frame">
          <div
            className={`group ${isTaskInputVisible ? "input-active" : ""}`}
            onClick={handleTaskInputToggle}
          >
            {isTaskInputVisible ? (
              <div className="task-input-wrapper show-send-icon">
                <textarea
                  type="text"
                  className="task-input"
                  rows="1"
                  placeholder="Enter task name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  ref={taskInputRef}
                  onClick={(e) => e.stopPropagation()}
                />
                <img
                  src="/icons/SendIcon.svg"
                  alt="Send"
                  className="filled-send-icon"
                  onClick={handleCreateTask}
                />
              </div>
            ) : (
              <>
                <img
                  src="/icons/PlusIcon.svg"
                  alt="Add Task"
                  className="filled-plus-icon"
                />
                <div className="tb">Add task</div>
              </>
            )}
          </div>
        </div>

        {isTaskInputVisible && (
          <div className="task-options">
            <div className="div">
            <DatePicker
              selected={deadline}
              onChange={(date) => setDeadline(date)} 
              locale="en-GB"
              customInput={<img src="/icons/CalendarActiveIcon.svg" />}
              className="icon-instance-node"
              ref={datePickerRef}
              showTimeSelect         
              timeFormat="HH:mm"     
              timeIntervals={1}     
              dateFormat="dd/MM/yyyy HH:mm"  
            />

              <div className="group-wrapper" onClick={handleDeadlineClick}>
                <div className="pobep-tb-wrapper">
                  <div className="pobep-tb">{formatDeadline(deadline)}</div>
                </div>
              </div>
              
            </div>

            <div className="div">
              <img
                src="/icons/FolderIcon.svg"
                alt="Incoming"
                className="icon-instance-node"
              />
              <div className="group-wrapper">
                <div className="group-2">
                  <select
                    className="task-input project-input"
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                  >
                    <option value="">Select Project</option>
                    {chats.length > 0 ? (
                      chats.map((chat) => (
                        <option key={chat.id} value={chat.id}>
                          {chat.name}
                        </option>
                      ))
                    ) : (
                      <option value="">No Projects Available</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {members.length > 0 && (
              <div className="div">
                <img
                  src="/icons/Executor.svg"
                  alt="User Icon"
                  className="icon-instance-node"
                />
                <div className="group-wrapper">
                <select
                className="task-input project-input"
                value={selectedExecutor}
                onChange={(e) => {
                  const selectedMember = members.find(member => member.username === e.target.value);
                  setSelectedExecutor(selectedMember.username); 
                  setSelectedExecutorId(selectedMember.id); 
                }}
              >
                <option value="">Select Executor</option>
                {members.map((member) => (
                  <option key={member.id} value={member.username}>
                    {member.username}
                  </option>
                ))}
              </select>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="view-2">
        <div className="groups-2 task-item">
          {visibleTasks.map((task) => (
              <Task 
                task={task}
                chats={chats}
                handleTaskClick={handleTaskClick}
                handleTaskComplete={handleTaskComplete}
                formatDeadline={formatDeadline}
              />
          ))}

          {!showAllTasks && filteredTasks.length > 4 && (
            <div className="all-tasks no-close" onClick={handleShowAllTasks}>
              <img
                className="group-3"
                alt="Archive Icon"
                src="/img/archive.png"
              />
              <div className="alltasks">All Tasks</div>
              <div className="count">{filteredTasks.length}</div>
              <img
                className="group-4"
                alt="Pro badge"
                src="/img/group-8-2.png"
              />
            </div>
          )}

          {completedTasks.length > 0 ? (
            <div className="all-tasks" onClick={handleToggleCompletedTasks}>
              <img
                className="group-3"
                alt="Archive Icon"
                src="/icons/Completed.svg"
              />
              <div className="alltasks">Completed Tasks</div>
              <div className="count">{completedTasks.length}</div>
              <img
                className={`group-4 ${showCompletedTasks ? "arrow-up" : "arrow-down"}`}
                alt="Arrow"
                src="/icons/ArrowRight.svg"
              />
            </div>
          ) : (filteredTasks.length === 0 && completedTasks.length === 0 && (
            <span className="no-text">Please add new task</span>
          ))}

          {showCompletedTasks && (
            <div className="completed-tasks no-close">
              {completedTasks.map((task) => (
                <Task 
                  task={task}
                  chats={chats}
                  handleTaskClick={handleTaskClick}
                  handleTaskComplete={handleTaskComplete2}
                  formatDeadline={formatDeadline}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="chats-list">
        {chats.length > 0 ? (
          chats.map((chat) => (
            <div
              key={chat.id}
              className="chat-item"
              onClick={() => handleChatClick(chat)}
            >
              <div className="chat-avatar">
                {chatAvatars[chat.id] ? (
                  <img src={chatAvatars[chat.id]} alt={chat.name} className="chat-avatar-image" />
                ) : (
                  <span className="chat-avatar-initials">
                    {getFirstCharacter(chat.name)}
                  </span>
                )}
              </div>
              <div className="chat-info">
                <div className="chat-name">{chat.name}</div>
              </div>
              <div className="chat-counter">
                {chatMembersCount[chat.id] || 0}
              </div>
              <img src="/icons/ArrowRight.svg" className="chat-arrow" />
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

    </div>
  );
};

export default App;
